import React,{Component} from 'react'
import {Grid,Card,CardContent,CardMedia,CardActionArea,CardActions,Button,Typography} from '@material-ui/core'
import './App.css'
import altius from './assets/image/altius.svg'
import Kc from './assets/image/kc.svg'
import cms from './assets/image/cms.png'
import crm from './assets/image/crm.png'
import GmlLogo from './assets/image/gml_logo.svg'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#9b003c',
            contrastText: '#FFFFFF',

        },
    } 
})
export default class index extends Component {
    render(){
        return(
            <div className='parent' >
                <div className='logo'>
                <img src={GmlLogo} className='gml-logo'/>
                </div>
                <div className='grid-item'>
                    <MuiThemeProvider theme={themeButton}>
                    <div>
                    <div className='card-item'>
                        <img src={Kc} className='shape'/>
                        <div className='content-card'>
                            <h4>Knowledge Center</h4>
                            <p>Manage knowledge together. Get everything you need to know and share your knowledge.</p>
                            <div className='cardfooter' >
                                <Button color='primary' onClick={()=>window.open('http://kc.onegml.com/','_blank')} className='button-login-landing' size="small" variant='contained'>Login</Button>
                            </div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <img src={cms} className='shape'/>
                        <div className='content-card'>
                            <h4>Content Management System ONE GML</h4>
                            <p>Update corporate web site content</p>
                            <div className='cardfooter' >
                                <Button onClick={()=>window.open('http://content.onegml.com','_blank')} color='primary' className='button-login-landing' size="small" variant='contained'>Login</Button>
                            </div>
                        </div>

                    </div>
                    </div>
                    <div>
                    <div className='card-item'>
                        <img src={altius} className='shape'/>
                        <div className='content-card'>
                            <h4>Altius</h4>
                            <p>Performance management system</p>
                            <div className='cardfooter' >
                                <Button color='primary' onClick={()=>window.open('https://www.onegml.com/altius','_blank')} className='button-login-landing' size="small" variant='contained'>Login</Button>
                            </div>
                        </div>
                    </div>
                    <div className='card-item'>
                        <img src={crm} className='shape'/>
                        <div className='content-card'>
                            <h4>Customer Relationship Management</h4>
                            <p>Increase Productivity, Convert More Leads & Make More Sales</p>
                            <div className='cardfooter' >
                                <Button onClick={()=>window.open('http://crm.onegml.com','_blank')} color='primary' className='button-login-landing' size="small" variant='contained'>Login</Button>
                            </div>
                        </div>
                    </div>
                    </div>
                    </MuiThemeProvider>
                </div>
                {/* <Grid container className='grid-child' >
                    <Grid item sm={5} xs={12} className="title" >
                    <img src={GmlLogo} className='gml-logo'/>
                    </Grid>
                    <Grid item sm={7} xs={12} className='title card-shape' >
                    <div>
                      <div ><img src={altius} className='shape2'/></div>
                      <Card className='card-feature' >
                              <CardContent style={{padding:0}}>
                                  <div className='cardhead' ></div>
                                  <div className='cardbody' >
                                  <Typography variant="h5" component="h5" style={{color:'#808080'}}>
                                      Altius
                                  </Typography>
                                  <Typography variant="body2" component="body2" style={{color:'#cccccc'}}>Performance management system.</Typography>
                                  </div>
                                  <div className='cardfooter' >
                                      <Button className='button-login-landing' size="small" ><a href='https://www.onegml.com/altius' style={{color:'white',textDecoration:'none'}}>Login</a></Button>
                                  </div>
                              </CardContent>
                      </Card>
                      </div>
                    <div>
                    <div ><img src={Kc} className='shape-km'/></div>
                    <Card className='card-feature' style={{margin:100}}>
                                <CardContent style={{padding:0}}>
                                    <div className='cardhead' ></div>
                                    <div className='cardbody' >
                                    <Typography variant="h6" component="h6" style={{color:'#808080'}}>
                                        Knowledge Center
                                    </Typography>
                                    <Typography variant="body2" component="body2" style={{color:'#cccccc'}}>Coming soon.</Typography>
                                    </div>
                                    <div className='cardfooter' >
                                    </div>
                                </CardContent>

                        </Card>
                    </div>


                    </Grid>
                </Grid> */}
            </div>
        )
    }
}
